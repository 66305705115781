import { graphql } from 'gatsby';
import groupBy from 'lodash.groupby';
import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../components/Layout';
import PageLink from '../components/PageLink';
import { mdxToEntities } from '../helpers';

const getTemplateDescription = (pagesForTemplate, template) => {
  const templateString = pagesForTemplate.length === 1 ? template : `${template}s`;
  return `${pagesForTemplate.length} ${templateString}`;
};

const TagTemplate = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const pages = mdxToEntities(data.pages);
  const pagesByTemplate = groupBy(pages, 'template');

  return (
    <Layout title={tag}>
      <h1>{`Tag: "${tag}"`}</h1>
      {Object.entries(pagesByTemplate).map(([template, pagesForTemplate]) => (
        <div key={template} className="page-link-group">
          <h2>{getTemplateDescription(pagesForTemplate, template)}</h2>
          {pagesForTemplate.map(page => (
            <PageLink key={page.title} className="mb-5" {...page} />
          ))}
        </div>
      ))}
    </Layout>
  );
};

TagTemplate.propTypes = {
  pageContext: PropTypes.shape({ tag: PropTypes.string.isRequired }).isRequired,
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default TagTemplate;

export const tagTemplateQuery = graphql`
  query TagTemplate($tag: String!) {
    pages: allMdx(filter: { frontmatter: { tags: { in: [$tag] }, draft: { ne: true } } }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            template
            description
          }
        }
      }
    }
  }
`;
